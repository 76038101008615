import { Button } from '@/components/ui/button'
import {
  OrganizationSwitcher,
  useOrganization,
  useOrganizationList,
  UserButton,
  useUser,
} from '@clerk/nextjs'
import { Home } from 'lucide-react'
import Link from 'next/link'
import { useEffect } from 'react'

export const CTAButton = ({
  analyticsKey,
  content,
  destination,
}: {
  destination: string
  content: string | React.ReactNode
  analyticsKey: string
}) => {
  return (
    <Link href={destination}>
      <Button
        variant={'link'}
        analyticsKey={analyticsKey}
        analyticsProperties={{
          section: 'public_site',
        }}
      >
        {content}
      </Button>
    </Link>
  )
}

const BottomNav = () => {
  const { user, isLoaded } = useUser()
  const { organization } = useOrganization()
  const orgList = useOrganizationList({
    userInvitations: { pageSize: 10 },
    userMemberships: { pageSize: 10 },
  })

  const isSubscribed = user?.publicMetadata.isSubscribed
  const onboarded = user?.publicMetadata.onboarded

  const renderOrganizationSwitcher = () => {
    if (
      (orgList.userInvitations.data?.length && orgList.userInvitations.data?.length > 0) ||
      (orgList.userMemberships.data?.length && orgList.userMemberships.data?.length > 0)
    ) {
      return <OrganizationSwitcher />
    }
  }

  const renderActionButton = () => {
    if (organization?.id) {
      return (
        <>
          <div className="hidden sm:block">
            <CTAButton
              analyticsKey="bottom_nav_go_to_dashboard_with_org"
              content="Dashboard"
              destination="/dashboard"
            />
          </div>
          <div className="sm:hidden">
            <CTAButton
              analyticsKey="bottom_nav_go_to_dashboard_with_org"
              content={<Home className="w-4 h-4" />}
              destination="/dashboard"
            />
          </div>
        </>
      )
    }

    if (user?.publicMetadata.isSubscribed) {
      if (!onboarded) {
        return (
          <CTAButton
            analyticsKey="bottom_nav_go_to_onboarding"
            content="Onboard"
            destination="/onboarding"
          />
        )
      }

      return (
        <CTAButton
          analyticsKey="bottom_nav_go_to_dashboard"
          content="Dashboard"
          destination="/dashboard"
        />
      )
    }

    return (
      <CTAButton analyticsKey="bottom_nav_go_to_pricing" content="Pricing" destination="/pricing" />
    )
  }

  useEffect(() => {
    user?.getOrganizationInvitations()
  }, [isLoaded])

  return (
    <div className="mt-20">
      <div className="fixed z-50 bottom-0 left-0 right-0 bg-white border-t border-zinc-200 py-3 px-4 flex justify-around items-center sm:hidden">
        {renderActionButton()}
        {renderOrganizationSwitcher()}
        <UserButton afterSignOutUrl="/" />
        <div className="w-6" />
      </div>
    </div>
  )
}

export default BottomNav

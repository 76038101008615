import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { trackIntercomEvent } from '@/utils/intercom'
import {
  OrganizationSwitcher,
  SignedIn,
  SignedOut,
  useOrganization,
  useOrganizationList,
  UserButton,
  useUser,
} from '@clerk/nextjs'
import {
  ChevronDown,
  DollarSign,
  LayoutDashboard,
  LineChart,
  LogIn,
  Mail,
  Menu,
  PackageSearch,
  UserCircleIcon,
} from 'lucide-react'
import Image from 'next/image'
import Link from 'next/link'
import React, { ReactNode, useEffect } from 'react'
import { Separator } from '../ui/separator'
import BottomNav from '@/components/navs/bottom-nav'

interface BenefitCardProps {
  title: string
  description: string
  CustomComponent?: ReactNode
  href: string
}

const DropdownLink: React.FC<BenefitCardProps> = ({
  title,
  description,
  CustomComponent,
  href,
}) => {
  return (
    <Link
      href={href}
      className="text-left w-full flex items-center text-zinc-500 hover:text-zinc-900"
    >
      {CustomComponent && <div className="mr-4">{CustomComponent}</div>}

      <div>
        <span className="font-semibold text-xs">{title}</span>
        {/* <br /> */}
        {/* <span className="font-medium text-xs">{description}</span> */}
      </div>
    </Link>
  )
}

export const CTAButton = ({
  analyticsKey,
  content,
  destination,
}: {
  destination: string
  content: string
  analyticsKey: string
}) => {
  return (
    <Link href={destination}>
      <Button
        variant={'link'}
        analyticsKey={analyticsKey}
        analyticsProperties={{
          section: 'public_site',
        }}
        className="m-0"
      >
        {content}
      </Button>
    </Link>
  )
}

const TopNav = () => {
  const { user, isLoaded } = useUser()

  useEffect(() => {
    user?.getOrganizationInvitations().then(() => {
      // console.log('res', res)
    })
  }, [isLoaded])

  const orgList = useOrganizationList({
    userInvitations: {
      pageSize: 10,
    },
    userMemberships: {
      pageSize: 10,
    },
  })

  const { organization } = useOrganization()

  const isSubscribed = user?.publicMetadata.isSubscribed
  const onboarded = user?.publicMetadata.onboarded

  const linkDest = () => {
    if (!user) {
      return '/'
    } else {
      if (!isSubscribed) {
        return '/'
      } else {
        if (!onboarded) {
          return '/onboarding'
        } else {
          return '/dashboard'
        }
      }
    }
  }

  const renderOrganizationSwitcher = () => {
    if (
      (orgList.userInvitations.data?.length && orgList.userInvitations.data?.length > 0) ||
      (orgList.userMemberships.data?.length && orgList.userMemberships.data?.length > 0)
    ) {
      // Remove the defaultOpen prop to prevent it from opening by default
      return <OrganizationSwitcher />
    }
  }

  const renderActionButton = () => {
    if (organization?.id) {
      return (
        <CTAButton
          analyticsKey="top_nav_go_to_dashboard_with_org"
          content="Dashboard"
          destination="/dashboard"
        />
      )
    }

    if (user?.publicMetadata.isSubscribed) {
      if (!onboarded) {
        return (
          <CTAButton
            analyticsKey="top_nav_go_to_onboarding"
            content="Onboard"
            destination="/onboarding"
          />
        )
      }

      return (
        <CTAButton
          analyticsKey="top_nav_go_to_dashboard"
          content="Dashboard"
          destination="/dashboard"
        />
      )
    }

    return (
      <CTAButton analyticsKey="top_nav_go_to_pricing" content="Pricing" destination="/pricing" />
    )
  }

  return (
    <>
      <div className="select-none py-5 z-10 items-center justify-between text-xs lg:flex grid grid-cols-2">
        <Link rel="canonical" href={linkDest()} className="flex justify-center w-fit">
          <Image
            className="object-scale-down h-10 w-56"
            priority={true}
            src="/files/logos/logo.webp"
            alt="Logo"
            width={1000}
            height={1000}
          />
        </Link>
        <div className="mr-auto hidden lg:block">
          <SignedOut>
            <div className="sm:hidden block">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Menu />
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-72 mr-12 py-4">
                  <Link href="/auth/signin" className="w-full">
                    <DropdownMenuItem>
                      <UserCircleIcon className="mx-6 text-zinc-400 " />

                      <span>Sign in</span>
                    </DropdownMenuItem>
                  </Link>
                  <DropdownMenuItem className="bg-zinc-800 text-primary-foreground hover:bg-zinc-800/90 px-4 py-2 mt-1 ">
                    <LogIn className="ml-3 mr-6 text-zinc-400" />
                    <Link href="/auth/signup" className="w-full">
                      <span>Sign up</span>
                    </Link>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
            <div className="md:ml-6 flex items-center hidden md:flex gap-4">
              <div className="group max-lg:border-b  max-lg:py-3 relative">
                <a
                  href="javascript:void(0)"
                  className="inline-flex items-center justify-center whitespace-nowrap rounded-lg text-xs font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-zinc-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 px-0 mx-0 m-0 text-zinc-800 underline-offset-4 hover:underline p-0  text-left"
                >
                  Tools <ChevronDown className="w-4 h-4 ml-1 hover" />
                </a>
                <ul className="rounded space-y-6 absolute top-5 max-lg:top-8 left-0 z-50 block space-y-2 shadow-lg bg-white max-h-0 overflow-hidden min-w-[250px] group-hover:opacity-100 group-hover:max-h-[900px] px-4 group-hover:pb-4 group-hover:pt-6 transition-all duration-500">
                  <DropdownLink
                    title="Price Analysis"
                    description="Expert AI Competitive Price Analysis"
                    href="/tools/competitive-price-analysis"
                  />

                  <DropdownLink
                    title="Product Comparison"
                    description="Accurate Product Comparison Tool"
                    href="/tools/product-comparison-tool"
                  />

                  <DropdownLink
                    title="Landing Page Comparison"
                    description="Comparison of Competitor Landing Pages"
                    href="/tools/competitor-landing-page-comparison"
                  />

                  <DropdownLink
                    title="SEO Monitoring"
                    description="The Ultimate SEO Monitoring Tool"
                    href="/tools/seo-monitor"
                  />

                  <DropdownLink
                    title="Email Monitoring"
                    description="Elevate Your Strategy with Email Monitoring"
                    href="/tools/competitor-email-monitoring"
                  />
                </ul>
              </div>
              <Link href="https://blog.opinly.ai/">
                <Button
                  analyticsKey="blog"
                  variant={'link'}
                  analyticsProperties={{
                    section: 'public_site',
                  }}
                >
                  Blog
                </Button>
              </Link>

              {/* <Link href={'/pricing'}>
                <Button
                  analyticsKey="pricing"
                  variant={'link'}
                  analyticsProperties={{
                    section: 'public_site',
                  }}
                >
                  Pricing
                </Button>
              </Link> */}
            </div>
          </SignedOut>
        </div>

        <div className="ml-auto mr-5">
          <SignedIn>
            {/* Desktop */}
            <div className="hidden sm:flex items-center">
              {renderActionButton()}
              {renderOrganizationSwitcher()}
              <UserButton />
            </div>
            {/* Mobile */}
            <div className="sm:hidden block">
              <BottomNav />
            </div>
          </SignedIn>

          <SignedOut>
            <div className="sm:hidden block">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Menu />
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-72 mr-12 py-4">
                  <Link
                    href="/auth/signin
                "
                    className="w-full"
                  >
                    <DropdownMenuItem>
                      <UserCircleIcon className="mx-6 text-zinc-400 " />

                      <span>Sign in</span>
                    </DropdownMenuItem>
                  </Link>
                  <DropdownMenuItem className="bg-zinc-800 text-primary-foreground hover:bg-zinc-800/90 px-4 py-2 mt-1 ">
                    <LogIn className="ml-3 mr-6 text-zinc-400" />
                    <Link href="/auth/signup" className="w-full">
                      <span>Sign up</span>
                    </Link>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
            <div className="flex items-center hidden sm:block grid gap-4">
              <Link href={'/auth/signin'} className="mr-4">
                <Button
                  analyticsKey="top_nav_signin"
                  variant={'neutralLink'}
                  analyticsProperties={{
                    section: 'public_site',
                  }}
                >
                  Sign in
                </Button>
              </Link>
              <Link
                className="h-8 bg-zinc-900 hover:bg-zinc-700 px-4 inline-flex items-center justify-center whitespace-nowrap rounded-lg text-xs font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-orange-500 text-primary-foreground hover:bg-orange-400 "
                href={'/auth/signup'}
                onClick={() => {
                  trackIntercomEvent({
                    eventName: 'top_nav_get_started',
                    metadata: {
                      section: 'public_site',
                    },
                  })
                }}
              >
                Get started
              </Link>
            </div>
          </SignedOut>
        </div>
      </div>
    </>
  )
}

export default TopNav

import { event } from '@opinly/core/src/events/event-constructor'

export interface IntercomMetadata {
  [key: string]: string | number | boolean | undefined
  section:
    | 'emails'
    | 'seo'
    | 'pricing'
    | 'features'
    | 'landing_pages'
    | 'public_site'
    | 'settings'
    | 'n/a'
    | 'competitor_management'
    | 'onboarding'
    | 'dashboard'
    | 'raw_data'
    | 'admin'
}

export const trackIntercomEvent = ({
  eventName,
  metadata,
}: {
  eventName: string
  metadata: IntercomMetadata
}) => {
  try {
    if (!window.Intercom) {
      return
    }

    window.Intercom('trackEvent', eventName, metadata)
  } catch (err) {
    console.error('intercom error', err)
  }
}
